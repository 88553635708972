import React from "react"

import "./style.scss"

class QuoteCard extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  render() {
    return (
      <div className={"quote-card " + (this.props.class ? this.props.class : "")}>
        <blockquote>
          <p className="quote-text">{this.props.quote}</p>
          <cite className="quote-cite">{this.props.cite}</cite>
        </blockquote>

      </div>
    )
  }
}

export default QuoteCard
